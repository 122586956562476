//have no fees when transferring stocks between pinx and each other and add an ambassador program 




import { createAsyncThunk } from '@reduxjs/toolkit';
import ShowToast from '../../../components/ShowToast/ShowToast';
import { JUPITER_API_URL, PLATFORM_FEE_BPS } from '../../../constants/constants';
import { VersionedTransaction, Connection, PublicKey } from '@solana/web3.js';

export const swapTokensThunk = createAsyncThunk(
  'swap/swapTokens',
  async (
    {
      wallet,
      connection,
      tokenTop,
      tokenBottom,
      topAmount,
      slippage,
    }: {
      wallet: any;
      connection: Connection;
      tokenTop: { mintAddress: string; decimals: number };
      tokenBottom: { mintAddress: string; decimals: number };
      topAmount: string;
      slippage: number;
    },
    { rejectWithValue }
  ) => {
    ////console.log('Starting swapTokensThunk...');

    if (!wallet.connected) {
      ShowToast({
        type: 'error',
        title: 'Wallet Not Connected!',
        text: 'Please connect your wallet before swapping.',
      });
      return rejectWithValue('Wallet not connected');
    }

    try {
      // Step 1: Fetch SOL balance for transaction fees
      const solBalance = await connection.getBalance(wallet.publicKey);
      ////console.log('Wallet SOL balance:', solBalance);
      if (solBalance < 1000000) {
        ShowToast({
          type: 'error',
          title: 'Insufficient SOL',
          text: 'You need more SOL to cover transaction fees.',
        });
        return rejectWithValue('Insufficient SOL for fees');
      }

      const inputAmountParsed = Math.floor(parseFloat(topAmount) * Math.pow(10, tokenTop.decimals));
      ////console.log('Parsed topAmount into lamports:', inputAmountParsed);

      const slippageBps = slippage * 100;
      const inputMint = new PublicKey(tokenTop.mintAddress);
      const outputMint = new PublicKey(tokenBottom.mintAddress);

      // Check if input mint is PINX
      const pinxMintAddress = '6m32vKEf22fZVV2NC69WNabDAx7WDN4ZSgGWjUJuRQEV';
      let feeAccount: PublicKey | undefined;
      let feeMint: PublicKey;

      if (inputMint.toBase58() === pinxMintAddress) {
        // Apply fee on output mint (e.g., USDC)
        feeMint = outputMint;
        ////console.log('Input Mint is PINX, applying fee on output mint.');
      } else {
        // Apply fee on input mint
        feeMint = inputMint;
        ////console.log('Input Mint is not PINX, applying fee on input mint.');
      }

      // Derive the fee account for the chosen mint (input or output)
      const referralProgramId = new PublicKey("REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3");
      const referralAccountPubkey = new PublicKey("GY98kjWMhGxhh7Q3fd56VrdnVrwYBnnb3rjh9ksDxd27");

      if (feeMint.toBase58() === "So11111111111111111111111111111111111111112") {
        const wSolMint = new PublicKey('So11111111111111111111111111111111111111112');
        [feeAccount] = PublicKey.findProgramAddressSync(
          [
            Buffer.from("referral_ata"),
            referralAccountPubkey.toBuffer(),
            wSolMint.toBuffer(),
          ],
          referralProgramId
        );
        
        //console.log('Derived Fee Account for SOL:', feeAccount.toBase58());
      } else {
        // Derive ATA for SPL tokens
        [feeAccount] = PublicKey.findProgramAddressSync(
          [
            Buffer.from("referral_ata"),
            referralAccountPubkey.toBuffer(),
            feeMint.toBuffer(),
          ],
          referralProgramId
        );
        //console.log("Derived Fee Account for SPL token:", feeAccount.toBase58());
      }



      //console.log("Referral Account PublicKey:", referralAccountPubkey?.toBase58());
      //console.log("Referral Program ID:", referralProgramId?.toBase58());
      
      //console.log("Fee Mint PublicKey:", feeMint.toBase58());
      //console.log("22");
      //console.log('Derived Fee Account:', feeAccount.toBase58());

      // Step 3: Fetch the quote from Jupiter API
      const quoteUrl = `${JUPITER_API_URL}/quote?inputMint=${inputMint.toBase58()}&outputMint=${outputMint.toBase58()}&amount=${inputAmountParsed}&slippageBps=${slippageBps}&platformFeeBps=${PLATFORM_FEE_BPS}&feeAccount=${feeAccount.toBase58()}`;
      ////console.log('Quote URL:', quoteUrl);

      const quoteResponse = await fetch(quoteUrl);
      const quoteData = await quoteResponse.json();

      if (!quoteResponse.ok || !quoteData || !quoteData.routePlan || quoteData.routePlan.length === 0) {
        ////console.log('No routes found for swap.');
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: 'No routes found for swap.',
        });
        return rejectWithValue('No routes found for swap');
      }

      ////console.log('Quote Data:', quoteData);

      // Step 4: Submit the swap request to Jupiter API
      const swapResponse = await fetch(`${JUPITER_API_URL}/swap`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quoteResponse: quoteData,
          userPublicKey: wallet.publicKey.toString(),
          wrapAndUnwrapSol: true,
          prioritizationFeeLamports: 750000,
          feeAccount: feeAccount.toBase58(),
          dynamicComputeUnitLimit: true,
        }),
      });

      if (!swapResponse.ok) {
        ////console.log('Swap API failed with status:', swapResponse.status);
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: `Swap API failed with status: ${swapResponse.status}`,
        });
        return rejectWithValue(`Swap API failed with status: ${swapResponse.status}`);
      }

      const swapResponseData = await swapResponse.json();
      const { swapTransaction } = swapResponseData;

      if (!swapTransaction) {
        ////console.log('No swap transaction found in response.');
        ShowToast({
          type: 'error',
          title: 'Swap Error',
          text: 'No swap transaction found in response.',
        });
        return rejectWithValue('No swap transaction found in response');
      }

      // Step 5: Deserialize and send the transaction
      const transactionBuffer = Buffer.from(swapTransaction, 'base64');
      const transaction = VersionedTransaction.deserialize(new Uint8Array(transactionBuffer));

      const signature = await wallet.sendTransaction(transaction, connection);
      ////console.log('Transaction sent with signature:', signature);

      // Step 6: Confirm the transaction
      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
      await connection.confirmTransaction({
        signature,
        blockhash,
        lastValidBlockHeight,
      });
      ////console.log('Transaction confirmed successfully.');

      ShowToast({
        type: 'success',
        title: 'Swap Successful!',
        text: (
          <a href={`https://solscan.io/tx/${signature}`} target="_blank" rel="noopener noreferrer">
            View on Solscan
          </a>
        ),
      });

      return signature;
    } catch (error: any) {
      ////console.error('Error during swap:', error.message || error);
      ShowToast({
        type: 'error',
        title: 'Swap Failed',
        text: error.message ? `Error: ${error.message}` : 'Please try Again',
      });

      return rejectWithValue(error.message || 'An unknown error occurred');
    }
  }
);
